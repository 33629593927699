import { Injectable } from '@angular/core';
import {
  ChargePointListAPIPerformanceMetrics,
  EquipmentCurrentStatusAPIPerformanceMetrics,
  MarkerMetrics,
} from '@core/services/datadog-types';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class DatadogMetricLoggerService {
  constructor() {}

  sendEquipmentStatusMetricsToDatadog(responseInfo: EquipmentCurrentStatusAPIPerformanceMetrics) {
    datadogRum.setGlobalContextProperty('dashboardV2', responseInfo);
  }

  chargePointApiPerformance(amountOfchargePoints: number, latitude: string, longitude: string, radius: number) {
    const resources = performance.getEntriesByType('resource');
    resources.forEach((entry) => {
      if (entry.name.includes('chargepoint/list')) {
        let perf: ChargePointListAPIPerformanceMetrics = {
          name: entry.name,
          duration: entry.duration,
          amountOfChargePoints: amountOfchargePoints,
          latitude: latitude,
          longitude: longitude,
          radius: radius,
        };
        this.sendChargePointMetricsToDatadog(perf);
      }
    });
  }

  sendChargePointMetricsToDatadog(responseInfo: ChargePointListAPIPerformanceMetrics) {
    datadogRum.setGlobalContextProperty('chargePointList', responseInfo);
  }

  costOfAddingMarkers(value: MarkerMetrics) {
    datadogRum.setGlobalContextProperty('AddMarkers', value);
  }

  costOfRemovingMarkers(value: MarkerMetrics) {
    datadogRum.setGlobalContextProperty('RemoveMarkers', value);
  }
}
